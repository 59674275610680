import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import NotFind from "@/views/NotFind.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "loading",
    component: () => import("../views/loading.vue"),
  },
  
  {
    path: "/home",
    name: "home",
    component: HomeView,
    redirect: "/Expert",
    children: [
      {
        path: "/Expert",
        name: "Expert",
        component: () => import("../views/Expert/Expert.vue"),
        meta: { path: "/Expert", title: "优选达人", requiresAuth: true },
      },
      {
        path: "/xhs",
        name: "xhs",
        component: () => import("../views/Expert/Expert.vue"),
        meta: { path: "/Expert", title: "hs达人", requiresAuth: true },
      },
      {
        path: "/Detail/:uid",
        name: "Detail",
        component: () => import("../views/Expert/Detail.vue"),
        meta: { path: "/Detail", title: "达人概览", requiresAuth: true },
      },
      {
        path: "/my",
        name: "my",
        component: () => import("../views/My/me.vue"),
        meta: { path: "/my", title: "个人中心", requiresAuth: true },
        redirect: "/myDetail",
        children: [
          {
            path: "/myDetail",
            name: "myDetail",
            component: () => import("../views/My/myDetail.vue"),
            meta: {
              path: "/myDetail",
              title: "个人信息管理",
              requiresAuth: true,
            },
          },
          {
            path: "/User",
            name: "User",
            component: () => import("../views/My/User.vue"),
            meta: {
              path: "/User",
              title: "用户管理",
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/cs",
    name: "cs",
    component: () => import("../views/cs.vue"),
  },
  //放到最底部 * 匹配所有
  { path: "*", component: NotFind },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const token = JSON.parse(localStorage.getItem("token"));

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 需要认证的路由
    if (!token) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    if (to.path === '/' && token) {
      // 如果已经有 token 则不允许进入登录页
      next('/home');
    } else {
      next();
    }
  }
});
// 添加全局后置守卫，防止登录后使用浏览器回退按钮返回登录页
router.afterEach((to, from) => {
  if (to.meta.title) {
    document.title = to.meta.title || ""; //修改网页的title
  } else {
    document.title = "风向标";
  }
  if (to.path !== "/" && from.path === "/") {
    window.history.pushState(null, null, window.location.href);
  }
});
export default router;
