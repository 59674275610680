<template>
  <div class="home">
    <el-container>
      <el-header>
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          :default-active="this.$route.path"
          router
        >
          <el-menu-item :index="'/Expert'">抖音达人</el-menu-item>
          <el-menu-item :index="'/xhs'">红书达人</el-menu-item>
          <el-submenu index="2">
            <template slot="title">工作台</template>
          <el-menu-item :index="'/my'">个人中心</el-menu-item>
            <el-menu-item  @click="loginout">
              <el-button size="mini" type="text"
                >退出登录</el-button
              >
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-header>
      <el-main>
        <keep-alive>
          <router-view :include="'Expert'"></router-view>
        </keep-alive>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    loginout() {
      localStorage.removeItem("token");

      this.$message({
        message: "退出成功",
      });
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss">
.el-header {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
