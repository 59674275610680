import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 样式
import "../src/assets/reset.css";
// import "../src/assets/common.css";
import "../public/flexible";

// 窗口自适应
import "lib-flexible/flexible.js";

// 适配flex
import "@/common/flexible.js";

import ElementUI from "element-ui";

import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);

import axios from "axios";

Vue.prototype.$axios = axios;

Vue.config.productionTip = false
// 自定义指令
Vue.directive('sticky', {
  inserted(el, binding) {
    const elTableHeader = el.querySelector('.el-table__header-wrapper');
    const stickyTop = binding.value || 0;

    const onScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const offsetTop = el.getBoundingClientRect().top + scrollTop;

      if (scrollTop > offsetTop) {
        elTableHeader.style.position = 'fixed';
        elTableHeader.style.top = stickyTop + 'px';
        elTableHeader.style.zIndex = 1000;
        elTableHeader.style.width = el.clientWidth + 'px'; // 防止表头宽度变化
      } else {
        elTableHeader.style.position = '';
        elTableHeader.style.top = '';
        elTableHeader.style.zIndex = '';
        elTableHeader.style.width = '';
      }
    };

    window.addEventListener('scroll', onScroll);

    el._onScroll = onScroll; // 保存引用以便在 unbind 钩子中移除事件监听
  },
  unbind(el) {
    window.removeEventListener('scroll', el._onScroll);
    delete el._onScroll;
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
